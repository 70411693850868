@font-face {
    font-family: nike-glyphs;
    src: url(//web.nike.com/glyphs/1.2/fonts/nike-glyphs.eot);
    src: url(//web.nike.com/glyphs/1.2/fonts/nike-glyphs.eot?#iefix) format('eot'), url(//web.nike.com/glyphs/1.2/fonts/nike-glyphs.woff) format('woff'), url(//web.nike.com/glyphs/1.2/fonts/nike-glyphs.ttf) format('truetype'), url(//web.nike.com/glyphs/1.2/fonts/nike-glyphs.svg#nike-glyphs) format('svg')
}

[class*=g72] {
    font-family: nike-glyphs;
    display: inline-block;
    line-height: inherit;
    /* vertical-align: top; */
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.g72-360:before {
    content: "\E001"
}

.g72-alert:before {
    content: "\E002"
}

.g72-arrow-fill-down:before {
    content: "\E003"
}

.g72-arrow-fill-left:before {
    content: "\E004"
}

.g72-arrow-fill-right:before {
    content: "\E005"
}

.g72-arrow-fill-up:before {
    content: "\E006"
}

.g72-arrow-thick-down:before {
    content: "\E007"
}

.g72-arrow-thick-left:before {
    content: "\E008"
}

.g72-arrow-thick-right:before {
    content: "\E009"
}

.g72-arrow-thick-up:before {
    content: "\E00A"
}

.g72-arrow-thin-left:before {
    content: "\E00B"
}

.g72-arrow-thin-right:before {
    content: "\E00C"
}

.g72-basketball:before {
    content: "\E00D"
}

.g72-cart:before {
    content: "\E00E"
}

.g72-chat:before {
    content: "\E00F"
}

.g72-check-circle:before {
    content: "\E010"
}

.g72-check:before {
    content: "\E011"
}

.g72-connected-apps:before {
    content: "\E012"
}

.g72-email:before {
    content: "\E013"
}

.g72-facebook:before {
    content: "\E014"
}

.g72-feed:before {
    content: "\E015"
}

.g72-filter:before {
    content: "\E016"
}

.g72-flag:before {
    content: "\E017"
}

.g72-friends:before {
    content: "\E018"
}

.g72-fuel:before {
    content: "\E019"
}

.g72-fuelband:before {
    content: "\E01A"
}

.g72-fullscreen:before {
    content: "\E01B"
}

.g72-gear:before {
    content: "\E01C"
}

.g72-gear2:before {
    content: "\E01D"
}

.g72-globe:before {
    content: "\E01E"
}

.g72-grid:before {
    content: "\E01F"
}

.g72-heart:before {
    content: "\E020"
}

.g72-help:before {
    content: "\E021"
}

.g72-home:before {
    content: "\E022"
}

.g72-hurley-icon:before {
    content: "\E023"
}

.g72-hurley:before {
    content: "\E024"
}

.g72-info:before {
    content: "\E025"
}

.g72-instagram:before {
    content: "\E026"
}

.g72-jordan:before {
    content: "\E027"
}

.g72-kinect:before {
    content: "\E028"
}

.g72-link:before {
    content: "\E029"
}

.g72-live-chat:before {
    content: "\E02A"
}

.g72-lock:before {
    content: "\E02B"
}

.g72-menu:before {
    content: "\E02C"
}

.g72-minus:before {
    content: "\E02D"
}

.g72-nano:before {
    content: "\E02E"
}

.g72-nikeid-circle:before {
    content: "\E02F"
}

.g72-nikeid:before {
    content: "\E030"
}

.g72-pause:before {
    content: "\E031"
}

.g72-payment-options:before {
    content: "\E032"
}

.g72-pin:before {
    content: "\E033"
}

.g72-pinterest:before {
    content: "\E034"
}

.g72-play:before {
    content: "\E035"
}

.g72-plus-large:before {
    content: "\E036"
}

.g72-plus:before {
    content: "\E037"
}

.g72-profile-share:before {
    content: "\E038"
}

.g72-profile:before {
    content: "\E039"
}

.g72-qzone:before {
    content: "\E03A"
}

.g72-rotate-left:before {
    content: "\E03B"
}

.g72-rotate-right:before {
    content: "\E03C"
}

.g72-running-gps:before {
    content: "\E03D"
}

.g72-search:before {
    content: "\E03E"
}

.g72-share:before {
    content: "\E03F"
}

.g72-snkrs:before {
    content: "\E040"
}

.g72-sportband:before {
    content: "\E041"
}

.g72-sportwatch:before {
    content: "\E042"
}

.g72-square:before {
    content: "\E043"
}

.g72-star:before {
    content: "\E044"
}

.g72-swoosh-circle:before {
    content: "\E045"
}

.g72-swoosh-plus:before {
    content: "\E046"
}

.g72-swoosh-word:before {
    content: "\E047"
}

.g72-swoosh:before {
    content: "\E048"
}

.g72-thumbs-down:before {
    content: "\E049"
}

.g72-thumbs-up:before {
    content: "\E04A"
}

.g72-training:before {
    content: "\E04B"
}

.g72-twitter:before {
    content: "\E04C"
}

.g72-volume-0:before {
    content: "\E04D"
}

.g72-volume-1:before {
    content: "\E04E"
}

.g72-volume-2:before {
    content: "\E04F"
}

.g72-volume-3:before {
    content: "\E050"
}

.g72-volume-mute:before {
    content: "\E051"
}

.g72-wechat:before {
    content: "\E052"
}

.g72-weibo:before {
    content: "\E053"
}

.g72-x-circle:before {
    content: "\E054"
}

.g72-x-small:before {
    content: "\E055"
}

.g72-x:before {
    content: "\E056"
}

.g72-youtube:before {
    content: "\E057"
}

.g72-zoom-in:before {
    content: "\E058"
}

.g72-zoom-out:before {
    content: "\E059"
}

.g72-clock:before {
    content: "\E05A"
}

.g72-converse:before {
    content: "\E05B"
}

.g72-nike-plus-snkrs:before {
    content: "\E05C"
}

.g72-edit:before {
    content: "\E05D"
}


/* @font-face {
   font-family: 'OneNikeGlyphs';
   src: url('https://web.nike.com/glyphs/1.2/fonts/nike-glyphs.woff');
} */
